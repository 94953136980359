import { Application } from "@/dto/application/application";
import { useChatStore } from "@/store/chat";
import { useFetchStateStore } from "@/store/fetch-state";

export const useApplicationStore = defineStore("application", () => {
  const applications = ref<Application[]>([]);
  const selectedApplication = ref<Application | null>(null);
  const fetchStateStore = useFetchStateStore();

  const totalUnreadApplications = computed(() => applications.value.filter((app) => !app.read).length);
  const fetching = computed(() => fetchStateStore.fetching["applications"]);

  const getApplications = async () => {
    await fetchStateStore.fetchOnce("applications", async () => {
      applications.value = await $api<Application[]>(API_ROUTES.application);
    });
  };

  const invite = async (appData: Partial<Application>) => {
    const application = await $api<Application>(API_ROUTES.applicationCreate, {
      method: "post",
      body: appData,
    });
    return application;
  };

  const inviteMany = async (appData: { job_id: number; profile_ids: string[]; status_id: number }) => {
    const applications = await $api<Application>(API_ROUTES.applicationCreateMany, {
      method: "post",
      body: appData,
    });
    return applications;
  };

  const updateApplication = async (appData: Partial<Application>, appId: number) => {
    const application = await $api<Application>(`${API_ROUTES.application}/${appId}`, {
      method: "patch",
      body: appData,
    });
    if (application) {
      const index = applications.value.findIndex((a: Application) => a.id === appId);
      applications.value[index] = application;
      // selectedApplication.value = null;
    }
  };

  const deleteApplication = async (appId: number) => {
    const application = await $api<Partial<Application>>(`${API_ROUTES.application}/${appId}`, {
      method: "delete",
    });
    if (application && application.job_id && application.profile_id) {
      removeApplicationFromStore(application.job_id, application.profile_id);
      const chatStore = useChatStore();
      chatStore.removeChatFromStore({ applicationId: application.id });
    }
  };

  const addApplicationToStore = (application: Application) => {
    if (!application) throw createError({ statusMessage: ERROR_MESSAGES.application_not_found });
    const index = applications.value.findIndex((a: Application) => a.id === application.id);
    index !== -1 ? (applications.value[index] = application) : applications.value.unshift(application);
  };

  const removeApplicationFromStore = (jobId: number, profileId: string) => {
    const index = applications.value.findIndex((a: Application) => a.job_id === jobId && a.profile_id === profileId);
    if (index !== -1) applications.value.splice(index, 1);
  };

  const getApplicationByPrimaryKeys = (jobId: number, profileId: string) => {
    return applications.value.find((a: Application) => a.job_id === jobId && a.profile_id === profileId);
  };

  return {
    applications,
    fetching,
    selectedApplication,
    getApplications,
    updateApplication,
    deleteApplication,
    invite,
    inviteMany,
    addApplicationToStore,
    removeApplicationFromStore,
    totalUnreadApplications,
    getApplicationByPrimaryKeys,
  };
});
